<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br /><br />
        <h3>ULTRASSONOGRAFIA MORFOLÓGICA 1° TRIMESTRE</h3>
        <br /><br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento }}
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong>Dra {{ medico.nome == 'Adriana Melo (curso)'? 'Adriana Melo': medico.nome }}</strong> </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
    <!--
      <h3>ULTRASSONOGRAFIA MORFOLÓGICA 1° TRIMESTRE</h3>

      <h4>Estática</h4>

      <p>Situação {{ laudo.situacao }}</p>

      <p>Apresentação {{ laudo.apresentacao }}.</p>

      <p>Dorso Fetal {{ laudo.dorsoFetal }}</p> -->

    <!-- <h4>Vitalidade:</h4>

      <p>Movimentos fetais presentes.</p>

      <p>Batimentos cardíacos fetais {{ laudo.batimentosCardiacosFetais }}</p>

      <p>Frequência de {{ laudo.frequenciaBpm }} bpm.</p>

      <p v-if="laudo.comentariosAdicionais1">
        Comentários Adcionais: {{ laudo.comentariosAdicionais1 }}
      </p> -->

    <!-- <h4>Biometria fetal:</h4>

      <p>Diâmetro biparietal {{ laudo.diametroBiparietal }} mm</p>

      <p>Circunferência cefálica {{ laudo.circunferenciaCefalica }} mm</p>

      <p>Circunferência abdominal {{ laudo.circunferenciaAbdominal }} mm</p>

      <p>Comprimento do úmero {{ laudo.comprimentoUmero }} mm</p>

      <p>Comprimento da ulna {{ laudo.comprimentoUlna }} mm</p>

      <p>Comprimento do rádio {{ laudo.comprimentoRadio }} mm</p>

      <p>Comprimento do femur {{ laudo.comprimentoFemur }} mm</p>

      <p>Comprimento da tíbia {{ laudo.comprimentoTibia }} mm</p> -->

    <!-- <folha> -->
    <!-- <p>Comprimento da fíbula {{ laudo.comprimentoFibula }} mm</p>

      <p>Comprimento do pé {{ laudo.comprimentoPe }} mm</p>

      <p>Diâmetro do cerebelo {{ laudo.diametroCerebelo }} mm</p>

      <p>Cisterna magna {{ laudo.cisternaMagna }} mm</p>

      <p>Prega cutânea occipital {{ laudo.pregaCutaneaOccipital }} mm</p>

      <p>Átrio do ventrículo lateral {{ laudo.atrioVentriculoLateral }} mm</p>

      <p>Osso nasal {{ laudo.ossoNasal }} mm</p>

      <p>
        Distância interorbitária interna
        {{ laudo.distanciaInterobitariaInterna }} mm
      </p>

      <p>
        Distância interorbitária externa
        {{ laudo.distanciaInterobitariaExterna }} mm
      </p>

      <p>Peso fetal {{ laudo.pesoFetal }} g ( + 10%)</p> -->

    <!-- <h4>Morfologia fetal:</h4>

      <p>Crânio {{ laudo.cranio }} {{ laudo.cranioTexto }}</p>

      <p>Encéfalo {{ laudo.encefalo }} {{ laudo.encefaloTexto }}</p>

      <p>Face {{ laudo.face }} {{ laudo.faceTexto }}</p>

      <p>Coluna {{ laudo.coluna }} {{ laudo.colunaTexto }}</p>

      <p>Tórax {{ laudo.torax }} {{ laudo.toraxTexto }}</p> -->

    <!-- <p>Coração</p>

      <p v-if="laudo.naoExaminado">Não examinado.</p>

      <p v-if="laudo.aparentementeNormal">
        Aparentemente normal com aorta abdominal à esquerda da coluna e presença
        de veia cava inferior situada à direita.
      </p>

      <p v-if="laudo.corteQuatro">
        Corte de quatro câmaras de aspecto ecografico normal para a idade
        gestacional.
      </p>
    </folha>

    <folha>
      <p v-if="laudo.corteTres">
        Corte dos três vasos e traqueia visibilizado e de aspecto normal. Timo
        visivel.
      </p>

      <p v-if="laudo.quatroCamarasEsquerda">
        Quatro câmaras assimetricas com dominância esquerda.
      </p>

      <p v-if="laudo.quatroCamarasDireita">
        Quatro câmaras assimétrica com dominância direita.
      </p>

      <p v-if="laudo.presencaDois">Presença de dois vasos no corte 3VT.</p>

      <p v-if="laudo.presencaQuatro">Presença de quatro vasos no corte 3VT.</p>

      <p v-if="laudo.traqueiaLoc">
        Traqueia localizada entre a aorta e pulmonar.
      </p>

      <p v-if="laudo.pulmonar">Pulmonar menor que a aorta.</p>

      <p v-if="laudo.dimensaoAorta">Dimensão da aorta similar a VCS.</p>

      <p v-if="laudo.aortaOriginando">
        Aorta originando-se do ventrículo direito.
      </p>

      <p v-if="laudo.pulmonarOriginando">
        Pulmonar originando-se do ventrículo esquerdo.
      </p>

      <p v-if="laudo.veia">Veia ázigos dilatada.</p>

      <p v-if="laudo.outroTexto">{{ laudo.outroTexto2 }}</p>

      <p>Abdome {{ laudo.abdome }} {{ laudo.abdomeOutro }}</p>

      <p>
        Sistema Urinário {{ laudo.sistemaUrinario }}
        {{ laudo.sistemaUrinarioOutro }}
      </p>

      <p>
        Diâmetro longitudinal do rim direito:
        {{ laudo.diametroLongitudinalRimDireito }} cm.
      </p>

      <p>
        Diâmetro longitudinal do rim esquerdo:
        {{ laudo.diametroLongitudinalRimEsquerdo }} cm.
      </p>

      <p>Pelve renal direita: {{ laudo.pelveRenalDireita }} cm.</p> -->

    <!-- <p>Pelve renal esquerda: {{ laudo.pelveRenalEsquerda }} cm.</p> -->

    <!-- <p>
        Sexo - Genitália externa morfologicamente
        {{ laudo.sexoGenitaliaExternaMorfologicamente }}
      </p>

      <p>Membros {{ laudo.membros }} {{ laudo.membrosOutro }}</p>

      <p>Líquido amniótico {{ laudo.liquidoAmniotico }}</p>

      <h4>Placenta</h4>

      <p>Localização {{ laudo.localizacao }} {{ laudo.localizacaoOutro }}</p>

      <p>Espessura de {{ laudo.espessura }} cm.</p>

      <p>Textura {{ laudo.textura }}</p>

      <p>Grau {{ laudo.grau }}</p>
    </folha> -->

    <!-- <p v-if="laudo.comentariosAdicionais2">
        Comentários adicionais: {{ laudo.comentariosAdicionais2 }}
      </p>

      <h4>Cordão umbilical</h4>

      <p>Inserção {{ laudo.insercao }}</p>

      <p>Vasos {{ laudo.vasos }}</p>

      <p v-if="laudo.comentariosAdicionais3">
        Comentários Adcionais: {{ laudo.comentariosAdicionais3 }}
      </p>

      <h4>Conclusão</h4>

      <p>Gestação única com feto vivo.</p>

      <p>
        Idade gestacional baseado na biometria fetal atual de
        {{ laudo.idadeGestacionalBiometriaFetalSemanas }} semanas e
        {{ laudo.idadeGestacionalBiometriaFetalDias }} dias,
        {{ laudo.compativelIncompativel }}
      </p>

      <p>Data provável do parto: {{ laudo.dataProvavelParto }} .</p>

      <p>{{ laudo.crescimentoFetal }}</p>

      <p v-if="laudo.comentariosAdicionais4">
        Comentários Adicionais {{ laudo.comentariosAdicionais4 }}
      </p>

      <h4>
        OBS: No momento do exame não se observam anomalias morfológicas fetais,
        entretanto não podemos descartar malformações que não tem expressão
        ecográfica ou se apresentam de forma tardia (sensibilidade do exame de
        85%). A critério clínico complementar avaliação cardiológica com
        especialista em ecocardiografia fetal.
      </h4> -->
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],
  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },
  mounted() {
    let folha = [];

    if (this.laudo.situacao) folha.push(`Situação ${this.laudo.situacao} `);
    if (this.laudo.apresentacao)
      folha.push(`Apresentação ${this.laudo.apresentacao}`);
    if (this.laudo.dorsoFetal)
      folha.push(`Dorso Fetal ${this.laudo.dorsoFetal}`);

    if (folha.length > 0) folha.splice(0, 0, 'Estática');
    this.estatica = [...folha];
    folha = [];

    folha.push(`Movimentos fetais presentes.`);
    if (this.laudo.batimentosCardiacosFetais)
      folha.push(
        `Batimentos cardíacos fetais ${this.laudo.batimentosCardiacosFetais}.`
      );
    if (this.laudo.frequenciaBpm)
      folha.push(`Frequência de ${this.laudo.frequenciaBpm} bpm.`);
    if (this.laudo.comentariosAdicionais1)
      folha.push(`${this.laudo.comentariosAdicionais1}`);

    if (folha.length > 0) folha.splice(0, 0, 'Vitalidade');
    this.vitalidade = [...folha];
    folha = [];

    if (this.laudo.batimentosCardiacosFetais)
      folha.push(`Diâmetro biparietal ${this.laudo.diametroBiparietal} mm.`);
    if (this.laudo.circunferenciaCefalica)
      folha.push(
        `Circunferência cefálica ${this.laudo.circunferenciaCefalica} mm.`
      );
    if (this.laudo.circunferenciaAbdominal)
      folha.push(
        `Circunferência abdominal ${this.laudo.circunferenciaAbdominal} mm.`
      );

    if (this.laudo.comprimentoUlna)
      folha.push(`Comprimento da ulna ${this.laudo.comprimentoUlna} mm.`);
    if (this.laudo.comprimentoRadio)
      folha.push(`Comprimento do rádio ${this.laudo.comprimentoRadio} mm.`);
    if (this.laudo.comprimentoFemur)
      folha.push(`Comprimento do femur ${this.laudo.comprimentoFemur} mm.`);
    if (this.laudo.comprimentoUmero)
      folha.push(`Comprimento do úmero ${this.laudo.comprimentoUmero} mm.`);
    if (this.laudo.comprimentoTibia)
      folha.push(`Comprimento da tíbia ${this.laudo.comprimentoTibia} mm.`);
    if (this.laudo.comprimentoFibula)
      folha.push(`Comprimento da fíbula ${this.laudo.comprimentoFibula} mm.`);
    if (this.laudo.comprimentoPe)
      folha.push(`Comprimento do pé ${this.laudo.comprimentoPe} mm.`);
    if (this.laudo.diametroCerebelo)
      folha.push(`Diâmetro do cerebelo ${this.laudo.diametroCerebelo} mm.`);
    if (this.laudo.cisternaMagna)
      folha.push(`Cisterna magna ${this.laudo.cisternaMagna} mm.`);
    if (this.laudo.pregaCutaneaOccipital)
      folha.push(
        `Prega cutânea occipital ${this.laudo.pregaCutaneaOccipital} mm.`
      );
    if (this.laudo.atrioVentriculoLateral)
      folha.push(
        `Átrio do ventrículo lateral ${this.laudo.atrioVentriculoLateral} mm.`
      );
    if (this.laudo.ossoNasal)
      folha.push(`Osso nasal  ${this.laudo.ossoNasal} mm.`);
    if (this.laudo.distanciaInterobitariaInterna)
      folha.push(`Distância interorbitária interna
    ${this.laudo.distanciaInterobitariaInterna} mm.`);
    if (this.laudo.distanciaInterobitariaExterna)
      folha.push(`Distância interorbitária externa
    ${this.laudo.distanciaInterobitariaExterna} mm.`);
    if (this.laudo.pesoFetal)
      folha.push(`Peso fetal ${this.laudo.pesoFetal} g ( + 10%).`);

    if (folha.length > 0) folha.splice(0, 0, 'Biometria fetal');
    this.biometriaFetal = [...folha];
    folha = [];

    if (this.laudo.pesoFetal)
      folha.push(
        `Crânio ${this.laudo.cranio} ${
          this.laudo.cranioTexto ? this.laudo.cranioTexto : ''
        }.`
      );
    if (this.laudo.encefalo)
      folha.push(
        `Encéfalo ${this.laudo.encefalo} ${
          this.laudo.encefaloTexto ? this.laudo.encefaloTexto : ''
        }.`
      );
    if (this.laudo.face)
      folha.push(
        `Face ${this.laudo.face} ${
          this.laudo.faceTexto ? this.laudo.faceTexto : ''
        }.`
      );
    if (this.laudo.coluna)
      folha.push(
        `Coluna ${this.laudo.coluna} ${
          this.laudo.colunaTexto ? this.laudo.colunaTexto : ''
        }.`
      );
    if (this.laudo.torax)
      folha.push(
        `Tórax ${this.laudo.torax} ${
          this.laudo.toraxTexto ? this.laudo.toraxTexto : ''
        }.`
      );

    if (folha.length > 0) folha.splice(0, 0, 'Morfologia fetal');
    this.morfFetal = [...folha];
    folha = [];

    if (this.laudo.naoExaminado) folha.push(`Não examinado.`);
    if (this.laudo.aparentementeNormal)
      folha.push(` Aparentemente normal com aorta abdominal à esquerda da coluna e presença
        de veia cava inferior situada à direita.`);
    if (this.laudo.corteQuatro)
      folha.push(`Corte de quatro câmaras de aspecto ecográfico normal para a idade
        gestacional.`);
    if (this.laudo.corteTres)
      folha.push(`Corte dos três vasos e traqueia visibilizado e de aspecto normal. Timo
        visivel.`);
    if (this.laudo.quatroCamarasEsquerda)
      folha.push(`Quatro câmaras assimetricas com dominância esquerda.`);
    if (this.laudo.quatroCamarasDireita)
      folha.push(`Quatro câmaras assimétrica com dominância direita.`);
    if (this.laudo.presencaDois)
      folha.push(`Presença de dois vasos no corte 3VT.`);
    if (this.laudo.presencaQuatro)
      folha.push(`Presença de quatro vasos no corte 3VT.`);
    if (this.laudo.traqueiaLoc)
      folha.push(`Traqueia localizada entre a aorta e pulmonar.`);
    if (this.laudo.pulmonar) folha.push(`Pulmonar menor que a aorta.`);
    if (this.laudo.dimensaoAorta)
      folha.push(`Dimensão da aorta similar a VCS.`);
    if (this.laudo.aortaOriginando)
      folha.push(`Aorta originando-se do ventrículo direito.`);
    if (this.laudo.pulmonarOriginando)
      folha.push(`Pulmonar originando-se do ventrículo esquerdo.`);
    if (this.laudo.veia) folha.push(`Veia ázigos dilatada.`);
    if (this.laudo.outroTexto) folha.push(`${laudo.outroTexto2}.`);
    if (this.laudo.aortaOriginando)
      folha.push(`Aorta originando-se do ventrículo direito.`);
    if (this.laudo.abdome)
      folha.push(
        `Abdômen ${this.laudo.abdome}${
          this.laudo.abdomeOutro ? this.laudo.abdomeOutro : ''
        }.`
      );
    if (this.laudo.sistemaUrinario)
      folha.push(
        ` Sistema Urinário ${this.laudo.sistemaUrinario}${
          this.laudo.sistemaUrinarioOutro ? this.laudo.sistemaUrinarioOutro : ''
        }.`
      );
    if (this.laudo.diametroLongitudinalRimDireito)
      folha.push(` Diâmetro longitudinal do rim direito:
        ${this.laudo.diametroLongitudinalRimDireito} cm.`);
    if (this.laudo.diametroLongitudinalRimEsquerdo)
      folha.push(`Diâmetro longitudinal do rim esquerdo:
        ${this.laudo.diametroLongitudinalRimEsquerdo} cm.`);
    if (this.laudo.pelveRenalDireita)
      folha.push(`Pelve renal direita: ${this.laudo.pelveRenalDireita} cm.`);
    if (this.laudo.pelveRenalEsquerda)
      folha.push(`Pelve renal esquerda: ${this.laudo.pelveRenalEsquerda} cm.`);

    if (this.laudo.sexoGenitaliaExternaMorfologicamente)
      folha.push(`Sexo - genitália externa morfologicamente
       ${this.laudo.sexoGenitaliaExternaMorfologicamente}.`);
    if (this.laudo.membros)
      folha.push(
        `Membros  ${this.laudo.membros}${
          this.laudo.membrosOutro ? this.laudo.membrosOutro : ''
        }.`
      );
    if (this.laudo.liquidoAmniotico)
      folha.push(`Líquido amniótico ${this.laudo.liquidoAmniotico}.`);
    folha.push(`Placenta`);
    if (this.laudo.localizacao)
      folha.push(
        `Localização ${this.laudo.localizacao}${
          this.laudo.localizacaoOutro ? this.laudo.localizacaoOutro : ''
        }.`
      );
    if (this.laudo.espessura)
      folha.push(`Espessura de ${this.laudo.espessura} cm.`);
    if (this.laudo.textura) folha.push(`Textura ${this.laudo.textura}.`);
    if (this.laudo.grau) folha.push(`Grau  ${this.laudo.grau}`);
    if (this.laudo.pelveRenalEsquerda)
      folha.push(`Pelve renal esquerda: ${this.laudo.pelveRenalEsquerda} cm.`);

    if (this.laudo.comentariosAdicionais2)
      folha.push(`${this.laudo.comentariosAdicionais2}`);
    //folha.push(`Cordão umbilical`);
    if (this.laudo.insercao) folha.push(`Inserção ${this.laudo.insercao}`);
    if (this.laudo.vasos) folha.push(`Vasos ${this.laudo.vasos}`);
    if (this.laudo.comentariosAdicionais3)
      folha.push(`${this.laudo.comentariosAdicionais3}`);

    if (folha.length > 0) folha.splice(0, 0, 'Morfologia fetal');
    this.morfFetal = [...folha];
    folha = [];

    folha.push(`Gestação única com feto vivo.`);
    if (this.laudo.idadeGestacionalBiometriaFetalSemanas)
      folha.push(`Idade gestacional baseado na biometria fetal atual de
    ${this.laudo.idadeGestacionalBiometriaFetalSemanas} semanas e
    ${this.laudo.idadeGestacionalBiometriaFetalDias} dias,
    ${this.laudo.compativelIncompativel}`);
    if (this.laudo.dataProvavelParto)
      folha.push(
        `Data provável do parto: ${this.dataFormatada(
          this.laudo.dataProvavelParto
        )}.`
      );
    if (this.laudo.crescimentoFetal)
      folha.push(`${this.laudo.crescimentoFetal}.`);
    if (this.laudo.comentariosAdicionais4)
      folha.push(`${this.laudo.comentariosAdicionais4}.`);
    folha.push(`obs`);

    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha];
    folha = [];

    const todos = [
      ...this.estatica,
      ...this.vitalidade,
      ...this.morfFetal,
      ...this.coracao,
      ...this.biometriaFetal,

      ...this.conclusao,
    ];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 32));
    }
  },
  data() {
    return {
      estatica: [],
      vitalidade: [],
      morfFetal: [],
      coracao: [],
      comentarios: [],

      conclusao: [],
      axilaDireitaDois: [],
      tamanhoElemento: [],
      titulos: [
        'Estática',
        'Vitalidade',
        'Biometria fetal',
        'Morfologia fetal',
        'Placenta',
        'Cordão umbilical',
        'Morfologia fetal',
        'Conclusão',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: -5px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: -5px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -3px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }

  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
